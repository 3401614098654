.activate-modal-title {
    padding-left: 8px;
    padding-right: 8px;
    .modal-head {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        .head-text {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 18px;
        }
    }
}
.activate-modal {
    padding-left: 8px;
    padding-right: 8px;
    .modal-box {
        background: #FFFFFF;
        border-radius: 19px;
    }
    .modal-text {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 14px;
        line-height: 124.4%;
        color: #3D3D3D;
    }
    .modal-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 80px;
        margin-left: 300px;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 14px;
        line-height: 124.4%;

        .blue-button {
            width: 181px;
            height: 40px;
            cursor: pointer;
            background: #1844AD;
            border: 1px solid #1844AD;
            text-align: center;
            color: #FFFFFF;
            border-radius: 11px;
            padding-top: 10px;
        }
    }

}