.no-data-over-all-wrapper {
    height: 75px;
    justify-content: center;
    align-items: center;
    width: 100%;

    .link {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.005em;
        text-decoration-line: underline;
        color: #123FBC;
        cursor: pointer;
    }

    .head-txt {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 20px;
        line-height: 108.4%;
        text-align: center;
        letter-spacing: -0.005em;
        color: #474747;

        .text-grey {
            color: #9F9F9F;
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 20px;
            line-height: 108.4%;
            letter-spacing: -0.005em;
        }
    }

    .sub-heading-text {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 13px;
        line-height: 117.4%;
        text-align: center;
        letter-spacing: -0.005em;
        color: #898989;
        font-family: 'Poppins';
        margin-top: 7px;
    }
}