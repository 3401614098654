.edit-external-form-parentDiv {
    overflow-y: auto;
    width: 100%;
    max-height: 890px;
    
    .question-titles-technical {
        height: 12px !important;
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 12px !important;
        line-height: 124.4% !important;
        letter-spacing: 0.005em !important;
        color: #3D3D3D !important;
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }

    .question-type-input{
      font-size: 13px;
      font-family: 'Poppins';
      color: #3d3d3d;
    }

    .add-Button-technical {
        height: 32px;
        width: 32px;
        color: white;
        background-color: #3D3D3D;
        border-radius: 4px;
        font-size: 20px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .minute p {
        color: gray !important;
        font-weight: 600 !important;
    }


    .minus-button-technical {
        height: 32px;
        width: 32px;
        margin-left: 5px;
    }


    .checkbox-label-technical {
        height: 18px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }



    .add-button-tag {
        width: fit-content;
        height: 23px;
        background-color: #D9D9D9;
        border-radius: 4px;
        color: black;
        align-items: flex-end;
        margin-top: 5px;
        padding: 0 4px;
        cursor: pointer;
    }



    .renderDropDown {
        max-width: 10em;
    }

    .tags-parent {
        .tag {
            color: white;
            display: flex;
            height: 24px;
            justify-content: space-between;
            margin: 2px;
            padding: 2px 5px;
            border-radius: 6px;
            background-color: #3D3D3D;
            align-items: center;

            .tag-text-technical {
                font-family: Poppins;
                font-size: 14px;
                margin: 2px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.025em;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 90px;
                width: fit-content;
            }

            .cross-icon {
                margin: 4px;
                height: 14px;
                width: 14px;
            }

        }

    }

    .default-point-box {
      border-radius: 6px;
      padding: 10px;
      background-color: #F0F3FF;
      margin-top: 10px;
      font-family: 'Poppins';
      font-size: 12px;

      .default-point-head {
        height: 12px;
        font-size: 12px;
        font-weight: 600;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #3D3D3D;
        margin-bottom: 10px;
      }

      .points-table {
        border-collapse: collapse;
        border-style: hidden;
        width: 90%;
        td, th {
          padding: 0.5rem;
          border: 1px solid #C9C9C9;
          text-align: center;
        }
      }
    }

}

.external-question-button-div {
  float: right;
  cursor: pointer;
  margin: 12px 0px;

  .cancelExternalQues {
    border: 1px solid #DBDBDB;
    color: #3D3D3D;
    background: #FFFFFF;
  }
  .saveExternalQues,  .saveExternalQues:hover{
    color: white;
    background: #123FBC;
  }
}

.option-div{
    margin-left: 0%;
    margin-top: 5px;

    .option-title{
      text-transform:capitalize;
      font-size: 12px;
      font-family: 'Poppins';
      color: #3D3D3D;
    }

    .option-div-text{
      width: 94%;
      margin: 5px 0 5px 10px;
    }
    
    .option-container{
      display: flex;
      width: 95%;
      align-items: center;
    }

    .radio-button{
      width: 16px;
      height: 16px;
      margin-top: -8px;
    }
  }

  .questionbg{
    background: #FFFFFF;
    border-radius: 14px;
    padding: 20px;
    width: 95%;
    margin: 20px;
  }

  .addoption{
    background: #FFFFFF;
    border-radius: 4px;
    padding: 5px 10px;
    width: fit-content;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    margin-left: 88px;
    color: #3D3D3D;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fs-13 {
    font-size: 13px;
  }

  .word-break {
    word-break: break-all;
  }