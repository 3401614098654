.swal-text {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #3d3d3d;
}

.swal-modal {
    width:500px;
 }

.swal-button {
  width: 150px;
  background-color: #fff;
  border: 1px solid #3d3d3d;
  border-radius: 0px;
  box-shadow: -1px -1px 30px rgba(0, 0, 0, 0.08);
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #3d3d3d;
}

.swal-button--cancel {
  background-color: #fff;
  border: 1px solid #3d3d3d;
  color: #3d3d3d;
}

.swal-button--confirm {
  background-color: #3d3d3d;
  color: #ffffff;
}

.swal-button--confirm:not([disabled]):hover{
    background: #495057;
}