.questionTopicContainer{
    padding: 1em 0;
    .questionTopicContent{
        padding: 1em 1.75em;
        .renderDropDown{

        }
        .topicSelect{
            margin-left:calc(100% - 7.5em);
        }
    }
    .questionTopicTitle{
        padding: 1em;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.005em;
        text-align: left;
    }
} 