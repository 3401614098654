.proceedColor{
    background-color: $proceedColor;
  }
  .onHoldColor{
    background-color: $onHoldColor;
  }
  .onRejectedColor{
    background-color: $onRejectedColor;
  }
  .onCancelledColor{
    background-color: $onCancelledColor;
  }
  .onDeletedColor{
    background-color: $onDeletedColor;
  }
  .table-hover tbody tr:hover .proceedColor, .table-hover tbody tr:hover .onHoldColor,
  .table-hover tbody tr:hover .onRejectedColor, .table-hover tbody tr:hover .onCancelledColor,
  .table-hover tbody tr:hover .onDeletedColor {
    background-color: $hoverColor;
  }
  
  .oneLineTxt{
    white-space: nowrap;
    text-align: center;
  }
  .opc-3-point-evnt{
    opacity: 0.3;
    pointer-events: none;
}
.width-10{
  width: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ql-editor {
  padding: 2px;
  overflow-y: unset;
}