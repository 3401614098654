.interview-prescreen-container {
    font-family: 'Poppins';
    background: #FFFFFF;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 29px 34px 25px 34px;
    margin-left: 45px;
    .text-transform-capitalize
    {
        text-transform: capitalize;
    }
    .header {
        .back-click {
            cursor: pointer;
        }

        .go-back {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 14px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #3D3D3D;
        }

        .prescreen-category-role {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 19px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #3D3D3D;
        }

        .template {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 12px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #848484;
            img
            {

                cursor: pointer;
            }
            .rename-prescreen
            {
                min-width:480px;
                max-width: fit-content;
                margin-top: 16px;
            }
        }
    }
}

.footer {
    margin-left: 45px;

    .arrow {
        min-width: 180px;
        background: #123FBC;
        border-radius: 7px;
        color: #FFFFFF;
        padding: 10px;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 14px;
        line-height: 117.4%;
        letter-spacing: 0.025em;
    }

    .arrow-disabled {
        background: #EDF3FF;
        color: #ABB5D0;
    }

    .text-align-right {
        text-align: end;
    }
}