.pmt-5 {
    margin-top: 5px;
}

.pmt-10 {
    margin-top: 10px;
}

.nmt-15 {
    margin-top: -15px;
}

.ml-10 {
    margin-left: 10px;
}

.bg-blue-1 {
    background-color: #EEF2FF;
}

.bg-blue-2 {
    background-color: #123FBC;
}

.fg-black {
    color: #3D3D3D;
}

.fg-black-2 {
    color: #494949;
}

.fg-gray {
    color: #ABABAB;
}

.fg-gray-2 {
    color: #9C9C9C;
}

.fg-white {
    color: white;
}

.fg-blue-1 {
    color: #7282AE;
}

.fg-blue-2 {
    color: #123FBC;
}

.bold-600 {
    font-weight: 600;
}

.bold-500 {
    font-weight: 500;
}

.bold-400 {
    font-weight: 400;
}

.font-32 {
    font-size: 32px;
}

.font-18 {
    font-size: 18px;
}

.font-16 {
    font-size: 16px;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

.proceedBtn{
    cursor: pointer;
    border: none;
}

.cancelBtn {
  background-color: '#981313'
}

.position_prescreen_card_main_component {
    background-color: #EEF2FF;
    padding: 10px;
    border-radius: 5px;

    a {
        text-decoration: none;
    }

    .font-style {
        font-family: 'Poppins';
        letter-spacing: 0.005em;
    }

    .button-1 {
        background-color: #123FBC;
        color: #FFFFFF;
    }

    .button-2 {
        color: #123FBC;
        border: 1px solid #123FBC;
    }

    .padding-5{
        padding: 6px 8px;
    }
    
    .button-1:hover {
        background: #123FBC;
        color: #FFFFFF;
    }

    .button-1,
    .button-2 {
        border-radius: 5px;
        padding: "2px 8px 2px 8px";
        text-transform: none;
    }

    .button-1>a,
    .button-2>a {
        text-decoration: none;
    }

    .button-1>a:hover {
        color: #FFFFFF;
    }

    .button-2>a:hover {
        color: #123FBC;
    }


}

.add-pos-popup{
    .set-prescreen-btn{
        background-color: #3D3D3D;
        color: #FFFFFF;
        width: 150px;
        padding: 4px;
    }
}
