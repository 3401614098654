.pagination {
    margin: 30px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a {
    background-color: #3d3d3d;
    border-radius: 6px;
    color: #fff;
    margin: 5px;
    padding: 8px 12px;

  }
  .pagination > li > a {
    background-color: #ededed;
    border-radius: 6px;
    color: #3d3d3d;
    margin: 5px;

    padding: 8px 12px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    background-color: #3d3d3d;
    outline: none;
  }
  .pagination > li > a,
  .pagination > li > span {
    color: #3d3d3d;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span,
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-radius: 6px;
  }