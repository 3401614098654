.dialog-parent-class {

    .button-dialogTitle-box-technical {
        display: flex;
        flex-direction: row;
        margin-top: 20px !important;
        margin-bottom: 30px !important;
    }

    .dialog-title-technical {
        height: 12px !important;
        font-family: 'Poppins';
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 124.4% !important;
        letter-spacing: 0.005em !important;
        color: #3D3D3D !important;
    }

    .MuiFormControl-root{
        width: 100%;
    }
    
    .MuiDialogContent-root{
        width: 100%;
    }
    
    .tag-textfield{
        width: 250px;
    }
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
        color: white !important;
        background-color: #123FBC !important;
        width: 118px !important;
        height: 30px !important;
        border-radius: 6px !important;
        font-size: 12px !important;
        font-family: 'Poppins' !important;
        font-weight: 600 !important;
        margin-right: 20px;
    }

    

    .close-popup-technical {
        cursor: pointer;
        margin-right: 10px;
    }

    .add-code-box {
        color:#123FBC;
        display: flex;
        gap: 20px;
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }

    .image-box {
        margin: 10px 5px;

        .image-style {
            height: 200px;
            width: fit-content;
        }
    }

    .integer-box {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .ans-label {
            font-size: 12px;
            font-weight: 600;
        }

        .ans-field {
            width: 20%;
            margin-top: 10px;
        }
    }

}