.question-bank-accordion {
  .font-style {
    font-family: "Poppins";
    font-style: normal;
    letter-spacing: 0.005em;
    color: #3d3d3d;
  }
  .col-style {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
  }

  .question-difficulty {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;
    color: #3D3D3D;
    text-transform: capitalize;
    padding: 0px 4px;
    border-radius: 4px;
  }
  .tick-icon-box {
    height: 13px;
    width: 13px;
    border-radius: 100%;
    background: #147d18;
    display: flex;
    align-items: center;
    justify-content: center;
    .tick-icon {
      color: white;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .dot {
    color: #3D3D3D;
    font-family: 'Poppins';
    font-weight: 900;
  }
  .skill-badge {
    width: fit-content;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    background: #EBF1FF;
    color: #3D3D3D;
    padding: 2px 4px;
    border-radius: 4px;
  }

  .role {
    font-weight: 600;
    font-size: 12px;
    color: #3D3D3D;
    font-family: 'Poppins';
    font-style: normal;
  }
  .zero-col {
    align-items: start;
    width: 5%;
    display: block;
  }
  .first-col {
    display: block;
    width: 45%;
    margin-top: 9px;
  }
  .column-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 55%;
  }
  .second-col {
    display: block;
    margin-top: 9px;
  }
  .third-col {
    display: block;
    margin-top: 9px;
    margin-right: 20px;
  }
  .fourth-col {
    display: block;
    margin-top: 10px;
  }
  .collapse-content {
    width: 90%;
    margin-left: 8%;
  }
  .question-link {
    text-decoration: underline;
    font-size: 11px;
    font-weight: 400;
    color: #123fbc;
    margin-top: 0.8%;
    cursor: pointer;
    display: block;
  }
  .quill-box-table {
    width: 90%;
    max-width: 420px;
    line-break: auto;
    word-break: break-word;
    display: block;
    img{
      display: none;
    }
    iframe{
      display: none;
    }
    p{
      line-break: auto;
      word-break: break-word;
      margin-bottom: 0.2rem;
    }
  }
  .fg-easy {
    color: #147d18;
  }
  .fg-medium {
    color: #3d3d3d;
  }
  .fg-hard {
    color: #b60000;
  }

  .bg-easy {
    background: #D4F9D4;
  }
  .bg-medium {
    background: #F9E2B7;
  }
  .bg-hard {
    background: #FCC4C0;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-13 {
    font-size: 13px;
  }
  .font-11 {
    font-size: 11px;
  }
  .font-400 {
    font-weight: 400;
  }
  .font-600 {
    font-weight: 600;
  }
  .fg-gray {
    color: #6b6b6b;
  }
  .d-flex {
    display: flex;
  }

    .option-box {
      display: flex;
      flex-direction: row;
      margin-left: 30px;
  
      .options-text {
        display: flex;
        flex-direction: row;
        font-family: 'Poppins';
        font-size: 13px;
        font-weight: 400;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #3D3D3D;
        gap: 30px;
        margin-top: 5px;
      }
  
      .right-tick {
        margin-left: 5px;
        height: 10px;
      }
    }
}

.no_result_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #2b2b2b;
}
.no_result_sub_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #2b2b2b;
  width: 70%;
}
