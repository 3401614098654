.AppNavBar{

    .navContainerLink{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .iconSize{
            height: 40px;
            width: 40px;
            margin-right: 5px;
            color: #343a40 !important;
        }
        .textHeading{
            text-transform: capitalize;
            font-weight: 500;
            color: #343a40;
        }
        &:hover{
            background: #d1f3ff;
            border-radius: 4px;
        }
    }

    .active {
        color: #033c73;
        background: #d1f3ff;
        border-radius: 4px;
        .textHeading{
            color: #033c73;
            font-weight: bold;
        }
    }

}