.prescreen-stepper-main-container {
    margin: 30px 0px;

    .arrow {
        width: 50px;
        height: 50px;
        background: #123FBC;
        border-radius: 7px;
        color: #FFFFFF;
        padding: 10px;
    }

    .arrow-disabled {
        background: #EDF3FF;
        color: #ABB5D0;
    }

    .stepper-container {
        width: 100%;

        .step-wrapper {
            width: 49%;
            background: #FFFFFF;
            border-top: 1px solid #919191;
            border-bottom: 1px solid #919191;
            box-sizing: border-box;
        }

        .step-wrapper:first-child {
            border-left: 1px solid #919191;
            border-radius: 6px 0px 0px 6px;
        }

        .step-wrapper:last-child {
            border-right: 1px solid #919191;
            border-radius: 0px 6px 6px 0px;
        }

        .step-wrapper-selected {
            border-top: 2px solid #123FBC;
            border-bottom: 2px solid #123FBC;
        }

        .step-wrapper-selected:first-child {
            border-left: 2px solid #123FBC;
            border-radius: 6px 0px 0px 6px;
        }

        .step-wrapper-selected:last-child {
            border-right: 2px solid #123FBC;
            border-radius: 0px 6px 6px 0px;
        }

        .pointer {
            cursor: pointer;
        }

        .not-allowed {
            cursor: not-allowed;
        }

        .step-label {
            width: 100%;
            text-align: center;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 15px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #636363;
            padding: 15px;
        }

        .selected {
            font-weight: 600;
            color: #123FBC;
        }
    }
}