.loader-style {
    position: fixed;
    top: 300px;
    left: 50%;
    z-index: 10;
    color: white !important;
}

.loading-indicator {
    align-items: center;
    display: flex;
    height: 400px;
    width: 100%;
    justify-content: center;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }